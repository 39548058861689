//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//DELETE
// import "./../../scss/main.scss"

export default class Nav extends React.Component {
  render() {
    return (
      <footer class="footer">
        <div class="container">
          <div class="row row-copyright d-flex flex-row justify-content-center align-items-center text-center">
            <span>&copy; 2020 Picohealth</span>
            <a
              href="https://picohealth.net/about/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <a
              href="https://picohealth.net/about/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </div>
        </div>
      </footer>
    )
  }
}
