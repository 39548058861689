//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//Amplify
import Cache from "@aws-amplify/cache"

//Assets
import Logo from "../../images/svg/picohealth-logo-02.svg"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  render() {
    let cid = Cache.getItem("cid")

    return (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <Link
            class="navbar-brand"
            activeClassName="navbar-brand"
            to={cid ? "/app/screener" : "/"}
          >
            <Logo />
          </Link>
          {/* <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="navbar-toggler-icon" />
      </button> */}
          <div class="collapse navbar-collapse navbar-main" id="navbarText" />
        </div>
      </nav>
    )
  }
}
