import Auth from "@aws-amplify/auth"
import API from "@aws-amplify/api"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"
import moment from "moment"

export function clearCache() {
  return (dispatch) => {
    dispatch({
      type: "USER_LOGOUT",
    })

    Cache.clear()
    Auth.signOut()
      .then((user) => {
        console.log("actions - userManagement - logoutUser - user")
        console.log(user)
        navigate("/")
      })
      .catch((error) => {
        console.log("actions - userManagement - logoutUser - error")
        console.log(error)
      })
  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: "USER_LOGOUT",
    })

    Auth.signOut()
      .then((user) => {
        console.log("actions - userManagement - logoutUser - user")
        console.log(user)
        Cache.removeItem("cid")

        navigate("/")
      })
      .catch((error) => {
        console.log("actions - userManagement - logoutUser - error")
        console.log(error)
      })
  }
}

export function checkPasswordStrength(password, password2) {
  if (
    password === password2 &&
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(password)
  ) {
    return true
  } else {
    return false
  }
}

function getUserRoles(sub, userObject) {
  return new Promise((resolve, reject) => {
    let apiName = "Picohealthweb"

    let path = "admin/auth/company/list"

    let jwtToken = userObject.signInUserSession.accessToken.jwtToken
    let options = {
      headers: {
        Authorization: jwtToken,
      },
      // queryStringParameters: {
      //   m: "list",
      // },
    }

    //begin promise
    API.get(apiName, path, options)
      .then((response) => {
        const data = response || {}

        console.log("actions - getUserRoles - API.get")
        console.log(data)

        resolve(data)
      })
      .catch((error) => {
        console.log("actions - getUserRoles - API.get - error")
        console.log(error)
        reject()
      })
  }) //end promise
}

export function getCompanyDetail(cid) {
  return (dispatch) => {
    getCompanyDetailData(cid)
      .then((result) => {
        console.log("result")
        console.log(result)
        dispatch({
          type: "COMPANY_DETAIL_REPLACE",
          data: result,
        })
      })
      .catch((error) => {
        console.log(
          "actions - userManagement - loginUser - getComgetCompanyDetailpanyDetailPlain - error2"
        )
        console.log(error)
      })
  }
}
function getCompanyDetailData(cid) {
  return new Promise((resolve, reject) => {
    if (!cid) cid = Cache.getItem("cid")

    let apiName = "Picohealthweb"

    let path = "admin/company/detail"

    Auth.currentAuthenticatedUser().then((user) => {
      //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
      console.log("actions - getCompanyDetailData - auth - user")
      console.log(user)

      let jwtToken = user.signInUserSession.accessToken.jwtToken
      let options = {
        headers: {
          Authorization: jwtToken,
        },
        queryStringParameters: {
          cid,
        },
      }

      //begin promise
      API.get(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log("actions - getCompanyDetailData - API.get")
          console.log(data)

          resolve(data)
        })
        .catch((error) => {
          console.log("actions - getCompanyDetailData - API.get - error")
          console.log(error)
          reject()
        })
    }) //end promise
  }).catch((error) => {
    console.log("actions - getCompanyDetailData - getAuth - error")
    console.log(error)
  }) //end get Auth token
}

export function getLoginData(user) {
  return (dispatch) => {
    let sub
    if (user && user.attributes && user.attributes.sub) {
      sub = user.attributes.sub
    } else if (user && user.username) {
      sub = user.username
    } else {
      dispatch({
        type: "USER_LOGIN_ERROR",
        data: {
          message: "We weren't able to log you in",
        },
      })
      return
    }

    dispatch({
      type: "LOADING_START",
    })

    getUserRoles(sub, user)
      .then((result) => {
        console.log(
          "actions - userManagement - loginUser - getUserRoles - result"
        )
        console.log(result)

        let selectedCompany = result.find(({ selected }) => selected === true)

        console.log(
          "actions - userManagement - loginUser - getUserRoles - selectedCompany"
        )
        console.log(selectedCompany)

        //null comparison is with two equal signs, See: https://stackoverflow.com/questions/2647867/how-can-i-determine-if-a-variable-is-undefined-or-null
        if (selectedCompany == null && result[0]) {
          selectedCompany = result[0]
        } else if (!result) {
          dispatch({
            type: "USER_LOGIN_ERROR",
            data: {
              message: "Couldn't get companies associated with this user",
            },
          })
          return
        }

        const expiration = moment().add(1, "day").valueOf()
        console.log(
          "actions - userManagement - loginUser - getUserRoles - expiration"
        )
        console.log(expiration)
        Cache.setItem("cid", selectedCompany.cid, { expires: expiration })

        getCompanyDetailData(selectedCompany.cid)
          .then((result2) => {
            console.log("result2")
            console.log(result2)
            dispatch({
              type: "COMPANY_DETAIL_REPLACE",
              data: result2,
            })
            dispatch({
              type: "USER_LOGIN",
              data: {
                action: "routeHome",
                user,
                cdata: result,
                // selectedCid: selectedCompany.cid,
              },
            })
            navigate("/app/screener/")
          })
          .catch((error2) => {
            console.log(
              "actions - userManagement - loginUser - getCompanyDetailData - error2"
            )
            console.log(error2)
            dispatch({
              type: "USER_LOGIN_ERROR",
              data: error2,
            })
          })
      })
      .catch((error) => {
        console.log(
          "actions - userManagement - loginUser - getUserRoles - error"
        )
        console.log(error)
        dispatch({
          type: "USER_LOGIN_ERROR",
          data: error,
        })
      })
  }
}

export function loginUser(username, password) {
  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })

    Auth.signIn(username, password)
      .then((user) => {
        console.log("actions - userManagement - loginUser - user")
        console.log(user)

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          console.log(
            "actions - userManagement - loginUser - NEW_PASSWORD_REQUIRED"
          )

          dispatch({
            type: "USER_LOGIN",
            data: { action: "forceNewPassword", user },
          })
        } else {
          console.log(
            "actions - userManagement - loginUser - NOT///NEW_PASSWORD_REQUIRED"
          )

          dispatch(getLoginData(user))
        } //end if/else NEW_PASSWORD_REQUIRED
      })
      .catch((error) => {
        console.log("actions - userManagement - loginUser - error")
        console.log(error)
        dispatch({
          type: "USER_LOGIN_ERROR",
          data: error,
        })
      })
  }
}
