//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//Components
import NavAppDropdownMenu from "./navAppDropdownMenu"

//Assets
import Logo from "../../images/svg/picohealth-logo-02.svg"

//DELETE
// import "./../../scss/main.scss"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  render() {
    return (
      <nav class="navbar navbar-expand navbar-light bg-light">
        <div class="container">
          <Link class="navbar-brand" activeClassName="navbar-brand" to="/">
            <Logo />
          </Link>
          <NavAppDropdownMenu />
          {/* <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="navbar-toggler-icon" />
          </button> */}
        </div>
      </nav>
    )
  }
}
