//React
import React from "react"
import PropTypes from "prop-types"

//Packages
import "babel-polyfill"
import "isomorphic-fetch"
import Helmet from "react-helmet"
import parse from "url-parse"

//Components
import NavMain from "./nav/navMain"
import NavMinimal from "./nav/navMinimal"
import FooterMinimal from "./nav/footerMinimal"
import FooterMinimalSticky from "./nav/footerMinimalSticky"

//Assets
import favicon from "../images/picohealth-icon.png"

//DELETE
import "./../scss/main.scss"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageContext: "",
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    console.log("((((url))))")
    console.log(url)
    if (url.pathname) {
      const split = url.pathname.split("/")
      console.log("((((split))))")
      console.log(split)
      if (split[1] === "about" && split[2] === "terms") {
        this.setState({ pageContext: "legal" })
      } else if (split[1] === "about" && split[2] === "privacy") {
        this.setState({ pageContext: "legal" })
      } else if (url.pathname === "/") {
        this.setState({
          pageContext: "home",
        })
      } else if (split[1] === "app" && split[2] === "password") {
        this.setState({ pageContext: "minimal" })
      } else {
        this.setState({ pageContext: split[1] })
      }
    }
  }

  render() {
    const { children } = this.props

    return (
      <div class="min-vh-100 d-flex flex-column">
        <Helmet
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
          ]}
        />

        {(() => {
          switch (this.state.pageContext) {
            case "minimal":
              return <NavMinimal />
            case "app":
              return <NavMain />
            default:
              return <NavMinimal />
          }
        })()}
        {children}

        {(() => {
          switch (this.state.pageContext) {
            case "app":
              return <FooterMinimal />
            default:
              return <FooterMinimalSticky />
          }
        })()}
      </div>
    )
  }
}

export default IndexPage
