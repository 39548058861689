//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Actions
import { logoutUser } from "../../actions/userManagement"

//Icons
import { MdMenu, MdExitToApp } from "react-icons/md"

//Redux
const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
})

class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="dropdown dropdown-app-menu">
        <button
          className="btn btn-app-menu"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <MdMenu size={23} />
        </button>
        <div
          className="dropdown-menu dropdown-menu-right fade black-3 border-1-blue-1"
          aria-labelledby="dropdownMenuButton"
        >
          <button
            className="dropdown-item d-flex flex-row"
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
            type="button"
            onClick={this.props.logoutUser}
          >
            <span>Sign out</span>
            <div style={{ marginLeft: "auto" }}>
              <MdExitToApp
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </button>
          {/* <Link className="dropdown-item d-flex flex-row" to="/">

        </Link> */}
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Nav)
